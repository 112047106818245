/**
 * @function RightPanelLayout
 * @param {number} goToNextPage
 * @returns It will contains the continue button and skip button.
 */

import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import classNames from 'classnames';
import SignupStyling from '../signUp.Module.scss';
import Headings from '../../Shared/Headings';
import { SetinitialValues } from '../../../helpers/signup';
import { useSelector } from 'react-redux';
import { Submitintrest } from '../../../helpers/signup/ChooseIntrest';
import { SubmitOtp } from '../../../helpers/signup/Otp';
import { googleAnalyticsTriggerFn } from '../../../utils/utility';
const STATUS_TYPE = {
  STATUS_O: 'NOT WAITING FOR AHURA EXTENSION',
  STATUS_1: "WAITING FOR AHURA EXTENSION",
};

const RightPanelLayout = ({
  headingType = 'h2',
  showContinueBtn = true,
  showSkipBtn = false,
  corpId = '',
  groupId = '',
  corpDetails = {},
  groupDetails = {},
  extensionInstalled = STATUS_TYPE['STATUS_O'],
  ...props
}) => {
  const { userData } = useSelector((state) => state);
  const [IsLoading, setIsLoading] = useState(false);
  const handleClick = () => {
    if (props.submitOTP) {
      SubmitOtp(
        props.otp,
        props?.emailId || userData?.UserClients[0]?.email,
        props.goToNextPage,
        setIsLoading
      );
    } else if (props.signUpApi) {
      props.signUpApiFunc();
    } else if (props.formik) {
      props.formik.validateForm().then((res) => {
        props.formik.setFieldTouched('sumbited');
        SetinitialValues(props.formik.values);
        if (Object.keys(res).length === 0) {
          props.goToNextPage();
        }
      });
    } else {
      const UserIntrest = [];
      if (props.InterestDataList) {
        props.InterestDataList.map((items) => {
          if (items.IsSelected) {
            UserIntrest.push(items.text);
          }
          return UserIntrest;
        });
        Submitintrest(UserIntrest, userData?.UserClients[0]?.user_id);
      }
      if (!props.submitOTP) {
        props.goToNextPage();
      }
    }
    googleAnalyticsTriggerFn(
      'Button',
      'Continue',
      'Continue button on sign up page clicked'
    );
  };

  return (
    <>
      <Headings
        headingType={headingType}
        classToOverride={`${SignupStyling.signUpHeading} fw-bolder`}
      >
        {props.heading}
      </Headings>
      <Headings
        headingType="h5"
        classToOverride={`${SignupStyling.pageLayoutDesc} fw-normal`}
      >
        {props.desc}
      </Headings>
      <div className={classNames(SignupStyling.pageLayoutChild)}>
        {props.children}
      </div>
      {showContinueBtn && (
        <Button
          variant="contained"
          className={classNames(
            ' w-100 py-3 fw-bold',
            SignupStyling.signupContinueButton,
            SignupStyling.signUpBtns,
            SignupStyling.backgroundColorBtn
          )}
          onClick={handleClick}
        >
          {IsLoading ? <CircularProgress color="inherit" /> : 'Continue'}
        </Button>
      )}
      {showSkipBtn && (
        <Button
          className={classNames('w-100 fw-bold', SignupStyling.signUpSkipBtns)}
          onClick={() => {
            console.log('extensionInstalled', extensionInstalled);
            if (extensionInstalled === STATUS_TYPE['STATUS_1']) {
              props.goToNextPage();
              props.goToNextPage();

            } else {
              props.goToNextPage();
            }

            googleAnalyticsTriggerFn(
              'Button',
              'Continue',
              'Continue button on sign up page clicked'
            );
          }}
        >
          {corpId && groupId ? 'Continue' : 'Skip'}
        </Button>
      )}
    </>
  );
};

export default RightPanelLayout;
