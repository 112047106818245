/**
 * @function RightPanelPage2
 * @param {number} goToNextPage
 * @param {string} emailId
 * @param {string} userId
 * @param {function} setUserId
 * @returns It will show the second page of the right panel with registration, password creation, and license details.
 */
import { KeyboardArrowDown } from '@mui/icons-material';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import RightPanelLayout from '../../RightPanelLayout';
import classNames from 'classnames';
import SignupStyling from '../../signUp.Module.scss';
import * as Yup from 'yup';
import {
  TextField,
  IconButton,
  InputAdornment,
  CircularProgress,
  FormHelperText,
  MenuItem,
  Select,
  Autocomplete,
  Button,
  Box,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import Form from 'react-bootstrap/Form';
import {
  initialValues,
  UserDataValidationSchema,
  licenseCodeValidate,
  ValidatePassword,
  onSubmitV2,
} from '../../../../helpers/signup';
import {
  GetLicenseClients,
  getAllGroups,
} from '../../../../helpers/signup/LicenseCode';
import Headings from '../../../Shared/Headings';
import { googleAnalyticsTriggerFn } from '../../../../utils/utility';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../../../redux/Actions/UserData';
import { SET_TOAST_ERROR } from '../../../../redux/Actions/Common';
import PasswordValidate from '../PasswordSet/passwordValidate';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Text from '../../../Shared/Text';
import { useLocation } from 'react-router-dom';

const RightPanelPage2 = ({ goToNextPage, emailId, userId, setUserId }) => {
  const [LicenseClient, setLicenseClient] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [corpDetails, setCorpDetails] = useState({ error: false });
  const [groupDetails, setGroupDetails] = useState({ error: false });
  const [loading, setLoading] = useState(true);
  const [isPasswordVisible, setisPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setisConfirmPasswordVisible] =
    useState(false);
  const [IsLoading, setIsLoading] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const groupId = (urlParams?.size > 0 && urlParams.get('group_id')) || '';
  const corpId = (urlParams?.size > 0 && urlParams.get('corp_id')) || '';

  const searchParams = new URLSearchParams(window.location.search);
  const organisation_name = searchParams.get('organisation_name');
  const organisation_id = searchParams.get('organisation_id');
  const firstRender = useRef(true); // Prevents infinite loop on first render
  // State for Autocomplete
  const [inputValue, setInputValue] = useState('');
  const [openOptPop, setOpenOptPop] = useState(false);

  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state);

  const SaveUserData = (resultData) => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length === 0) {
        resultData && resultData.data && dispatch(setUserData(resultData.data));

        goToNextPage();
      }
    });
  };

  const CombinedValidationSchema = Yup.object().shape({
    ...UserDataValidationSchema.fields,
    ...ValidatePassword.fields,
    ...licenseCodeValidate.fields,
  });

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      email: emailId || '',
      license_client: { client_id: '' }, // Ensure it exists
      neighbourhood_id: organisation_id || '', // Ensure it's set at initialization
    },
    validationSchema: CombinedValidationSchema,
    onSubmit: onSubmitV2({
      SaveUserData: SaveUserData,
      setIsLoading: setIsLoading,
      setUserId: setUserId,
    }),
  });

  useEffect(() => {
    console.log('Formik Values:', formik.values);
  }, [formik.values]);

  const isCorpExist = useCallback(() => {
    const newfilteredCorp = LicenseClient?.filter(
      (item) => item?.client_id === corpId
    );

    if (newfilteredCorp?.length > 0) {
      setCorpDetails((prevState) => ({
        ...prevState,
        id: corpId,
        name: newfilteredCorp[0]?.client_name,
        error: false,
      }));
    } else {
      setCorpDetails((prevState) => ({
        ...prevState,
        id: '',
        name: '',
        error: "Organization doesn't exist",
      }));
      setLoading(false);
      dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: "Organization doesn't exist.",
        })
      );
    }
  }, [LicenseClient, corpId, dispatch]);

  const isGroupExist = useCallback(() => {
    const newfilteredGroup = groupList?.filter((item) => item?.id === groupId);

    if (newfilteredGroup?.length > 0) {
      setGroupDetails((prevState) => ({
        ...prevState,
        id: groupId,
        name: newfilteredGroup[0]?.name,
        error: false,
      }));
    } else {
      setGroupDetails((prevState) => ({
        ...prevState,
        id: '',
        name: '',
        error: "Group doesn't exist",
      }));
      dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: "Group doesn't exist",
        })
      );
      setLoading(false);
    }
  }, [groupList, groupId, dispatch]);

  useEffect(() => {
    if (LicenseClient.length === 0) {
      GetLicenseClients(setLicenseClient);
    }
  }, [LicenseClient]);

  // Find organisation in LicenseClient (case-insensitive)
  const selectedOrg = LicenseClient.find(
    (items) =>
      items.client_name.toLowerCase() === organisation_name?.toLowerCase()
  );

  // Convert LicenseClient data to Autocomplete format
  const selectLabel = LicenseClient.map((item) => ({
    label: item.client_name,
    value: item,
  }));

  useEffect(() => {
    if (firstRender.current) {
      if (selectedOrg) {
        formik.setFieldValue('license_client', selectedOrg);

        if (!organisation_name) {
          formik.setFieldValue('neighbourhood_id', organisation_id);
        }

        firstRender.current = false; // Prevents further updates
        console.log('SelectedOrg', selectedOrg);
      }
    }
  }, [selectedOrg, formik, organisation_id, organisation_name]);

  useEffect(() => {
    if (LicenseClient?.length > 0) {
      if (corpId && groupId) {
        isCorpExist();
      } else {
        setLoading(false);
      }
    }
  }, [LicenseClient, corpId, groupId, isCorpExist]);

  useEffect(() => {
    if (groupList?.length === 0 && corpId && groupId && corpDetails?.id) {
      getAllGroups(setGroupList, userId, corpId);
    }
  }, [groupList, corpDetails, corpId, groupId, userId]);

  useEffect(() => {
    if (corpId && groupId && groupList?.length > 0) {
      isGroupExist();
    }
  }, [corpId, groupId, groupList, isGroupExist]);

  const styles = makeStyles((theme) => ({
    label: {
      fontSize: '14px',
    },
    root: {
      '& .MuiFilledInput-root': {
        background: '#c6d3d2',
      },
    },
  }));
  const classes = styles();

  return (
    <>
      <RightPanelLayout
        formik={formik}
        headingType="h2"
        heading="Register"
        desc="Enter dyour information to complete the registration process with Ahura AI."
        goToNextPage={goToNextPage}
        showContinueBtn={false}
        showBackBtn={false}
        submitForm={true}
        corpDetails={corpDetails}
        groupDetails={groupDetails}
        groupId={groupId}
        corpId={corpId}
        signUpApi={true}
        signUpApiFunc={formik.handleSubmit}
      >
        <form>
          <TextField
            type="text"
            name="first_name"
            placeholder="First Name"
            variant="outlined"
            id="first_name"
            inputProps={{ className: ` fw-bold ${SignupStyling.signUpFields}` }}
            className={`${SignupStyling.signUpFieldsContainer} w-100 mb-2`}
            value={formik?.values?.first_name}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={
              (formik?.touched.first_name || formik?.touched.sumbited) &&
              formik?.errors?.first_name
                ? true
                : false
            }
            helperText={
              (formik?.touched.first_name || formik?.touched.sumbited) &&
              formik?.errors?.first_name
            }
          />
          <TextField
            type="text"
            value={formik?.values?.last_name}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={
              (formik?.touched.last_name || formik?.touched.sumbited) &&
              formik?.errors?.last_name
                ? true
                : false
            }
            helperText={
              (formik?.touched.last_name || formik?.touched.sumbited) &&
              formik?.errors?.last_name
            }
            name="last_name"
            id="last_name"
            placeholder="Last Name"
            variant="outlined"
            inputProps={{ className: `${SignupStyling.signUpFields} fw-bold ` }}
            className={`${SignupStyling.signUpFieldsContainer} w-100 mb-2`}
          />

          <TextField
            type="email"
            value={formik?.values?.email}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={
              (formik?.touched?.email || formik?.touched.sumbited) &&
              formik?.errors?.email
                ? true
                : false
            }
            helperText={
              (formik?.touched?.email || formik?.touched.sumbited) &&
              formik?.errors?.email
            }
            name="email"
            id="email"
            placeholder="Email"
            variant="outlined"
            inputProps={{ className: ` fw-bold ${SignupStyling.signUpFields}` }}
            className={`${SignupStyling.signUpFieldsContainer} w-100 mb-2`}
          />
          <br />
          <br />
          <FormControlLabel
            classes={{
              label: classes.label,
            }}
            control={
              <Form.Check
                type="checkbox"
                id={`check-api-checkbox`}
                className="mx-2"
              >
                <Form.Check.Input
                  type="checkbox"
                  isValid={formik?.values?.accept_terms ? true : false}
                  isInvalid={
                    formik?.values?.accept_terms === false &&
                    formik?.touched.sumbited
                      ? true
                      : false
                  }
                  required={true}
                  className={SignupStyling.crossIcon}
                  onChange={formik?.handleChange}
                  name="accept_terms"
                  id="accept_terms"
                />
              </Form.Check>
            }
            label={
              <>
                Ahura AI is committed to leading industry data protection
                standards, and uses your data to personalize your learning
                experience. Check to agree to our{' '}
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={SignupStyling.link}
                >
                  Terms and Conditions
                </a>{' '}
                and{' '}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={SignupStyling.link}
                >
                  Privacy Policy
                </a>
                .
              </>
            }
            name="accept_terms"
            id="accept_terms"
            onChange={formik?.handleChange}
          />
        </form>
      </RightPanelLayout>
      <br />
      <RightPanelLayout
        headingType="h3"
        formik={formik}
        heading="Create a Password"
        desc="Create a unique password to setup your account."
        goToNextPage={goToNextPage}
        signUpApi={true}
        showContinueBtn={false}
        signUpApiFunc={formik.handleSubmit}
      >
        <form className="w-100">
          <TextField
            type={isPasswordVisible ? 'text' : 'password'}
            placeholder="Password"
            name="pass"
            id="pass"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    onClick={() => setisPasswordVisible(!isPasswordVisible)}
                  >
                    {isPasswordVisible ? <VisibilityOff /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
              className: ` fw-bold ${SignupStyling.signUpFields}`,
            }}
            className={`${SignupStyling.signUpFieldsContainer} w-100 mb-2`}
            value={formik?.values?.pass}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={
              (formik?.touched.pass || formik?.touched.sumbited) &&
              formik?.errors?.pass
                ? true
                : false
            }
            helperText={
              (formik?.touched.pass || formik?.touched.sumbited) &&
              formik?.errors?.pass
            }
          />
          <TextField
            type={isConfirmPasswordVisible ? 'text' : 'password'}
            id="confirm_pass"
            name="confirm_pass"
            placeholder="Repeat Password"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    onClick={() =>
                      setisConfirmPasswordVisible(!isConfirmPasswordVisible)
                    }
                  >
                    {isConfirmPasswordVisible ? (
                      <VisibilityOff />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              className: ` fw-bold ${SignupStyling.signUpFields}`,
            }}
            className={`${SignupStyling.signUpFieldsContainer} w-100 mb-2`}
            value={formik?.values?.confirm_pass}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={
              (formik?.touched.confirm_pass || formik?.touched.sumbited) &&
              formik?.errors?.confirm_pass
                ? true
                : false
            }
            helperText={
              (formik?.touched.confirm_pass || formik?.touched.sumbited) &&
              formik?.errors?.confirm_pass
            }
          />
        </form>
        <PasswordValidate />
      </RightPanelLayout>
      <br />
      <RightPanelLayout
        headingType="h3"
        heading="Licenses"
        desc={`To purchase licenses, please reach out to us at :`}
        goToNextPage={goToNextPage}
        showContinueBtn={false}
        showBackBtn={false}
        submitForm={true}
        formik={formik}
        corpDetails={corpDetails}
        groupDetails={groupDetails}
        groupId={groupId}
        corpId={corpId}
      >
        <a
          href="mailto:sales@ahuraai.com"
          onClick={() => {
            googleAnalyticsTriggerFn(
              'Hyperlink',
              'sales@ahuraai.com',
              'clicked on sales@ahuraai.com om signUp'
            );
          }}
          className={classNames(SignupStyling.mailLink)}
        >
          sales@ahuraai.com
        </a>
        <div>
          <Headings
            classToOverride={`fw-bold ${SignupStyling.purchaseDividertext} text-center my-4`}
            headingType="h4"
          >
            OR
          </Headings>
          {/* Integrated LicensecodeEntryPanalV2 Component */}
          <>
            <Headings
              headingType="h5"
              classToOverride={` ${SignupStyling.pageLayoutDesc} fw-normal`}
            >
              Gain unlimited access to all Premium learning experiences for you
              and your organization.
            </Headings>

            {groupId && corpId && groupDetails?.id && corpDetails?.id && (
              <>
                <div className="d-flex justify-content-start">
                  <Text
                    textType="t18"
                    classToOverride={classNames('text-black')}
                  >
                    Organisation Name:
                  </Text>
                  &nbsp;
                  <Text
                    textType="t18"
                    classToOverride={classNames(SignupStyling.groupCorpInfo)}
                  >
                    {corpDetails?.name}
                  </Text>
                </div>

                <div className="d-flex justify-content-start">
                  <Text
                    textType="t18"
                    classToOverride={classNames('text-black')}
                  >
                    Group Name:
                  </Text>
                  &nbsp;
                  <Text
                    textType="t18"
                    classToOverride={classNames(SignupStyling.groupCorpInfo)}
                  >
                    {groupDetails?.name}
                  </Text>
                </div>
              </>
            )}

            {(!groupId || !corpId) && (
              <>
                {/* <Select
                  IconComponent={KeyboardArrowDown}
                  variant="outlined"
                  name="license_client"
                  id="license_client"
                  value={formik?.values?.license_client}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    (formik?.touched.license_client ||
                      formik?.touched.license_client?.max_licenses) &&
                    formik?.errors?.license_client?.max_licenses
                      ? true
                      : false
                  }
                  inputProps={{
                    className: `fw-bold ${SignupStyling.signUpFields} `,
                  }}
                  MenuProps={{
                    PaperProps: {
                      className: `${SignupStyling.LicenseClientDrop}`,
                    },such that iSelect is readonly 
                  }}
                  className={`${SignupStyling.signUpFieldsContainer} w-100 `}
                >
                  <MenuItem value="none" disabled>
                    Select Neighbourhood
                  </MenuItem>
                  {LicenseClient?.map((items) => (
                    <MenuItem
                      key={items.client_id}
                      className={`py-3 w-100 ${
                        formik?.values?.license_client === items.client_id &&
                        classNames(` ${SignupStyling.SelectedItem}`)
                      }`}
                      value={items}
                    >
                      {items.client_name}
                    </MenuItem>
                  ))}
                </Select> */}
                <div>
                  {/* Conditionally Render Select or Autocomplete */}
                  {selectedOrg ? (
                    <Select
                      IconComponent={KeyboardArrowDown}
                      variant="outlined"
                      name="license_client"
                      id="license_client"
                      value={formik?.values?.license_client || ''}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      disabled
                      error={
                        (formik?.touched.license_client ||
                          formik?.touched.license_client?.max_licenses) &&
                        formik?.errors?.license_client?.max_licenses
                          ? true
                          : false
                      }
                      inputProps={{
                        className: `fw-bold ${SignupStyling.signUpFields}`,
                      }}
                      MenuProps={{
                        PaperProps: {
                          className: `${SignupStyling.LicenseClientDrop}`,
                        },
                      }}
                      className={`${SignupStyling.signUpFieldsContainer} w-100 `}
                    >
                      {selectedOrg ? (
                        <MenuItem value={selectedOrg} disabled>
                          {selectedOrg.client_name}
                        </MenuItem>
                      ) : (
                        <MenuItem value="none" disabled>
                          Organisation not found
                        </MenuItem>
                      )}
                    </Select>
                  ) : (
                    <div>
                      <Autocomplete
                        className={`${SignupStyling.signUpFieldsContainer} w-100 mb-2`}
                        sx={{
                          width: '100%', // Enforce same width as TextFields
                          '& .MuiAutocomplete-inputRoot': {
                            paddingRight: '40px', // Space for icon
                            paddingLeft: '0px', // Ensure no extra padding
                          },
                          '& .MuiOutlinedInput-root': {
                            minWidth: '100%',
                            maxWidth: '100%',
                            padding: '0px', // Reset internal padding
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            display: 'none', // Hide default MUI popup icon
                          },
                        }}
                        inputValue={inputValue}
                        popupIcon={''} // Disable default popup icon
                        onInputChange={(e, newValue) => {
                          setOpenOptPop(true);
                          setInputValue(newValue);
                        }}
                        // onChange={(e, newValue) => {
                        //   setOpenOptPop(false);
                        //   formik.setFieldValue(
                        //     'license_client',
                        //     newValue || null
                        //   ); // Store only the selected org
                        // }}

                        onChange={(e, newValue) => {
                          setOpenOptPop(false);

                          // Ensure license_client always has a consistent structure
                          formik.setFieldValue(
                            'license_client',
                            newValue ? newValue.value : null // Store only the object inside "value"
                          );
                        }}
                        id="license_client"
                        options={selectLabel}
                        isOptionEqualToValue={(option, value) =>
                          option?.value === value?.value
                        }
                        getOptionLabel={(option) => option?.label || ''} // Display correct text
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Type Organization Name"
                            variant="outlined"
                            name="license_client"
                            id="license_client"
                            onBlur={formik?.handleBlur}
                            inputProps={{
                              ...params.inputProps,
                              className: `${SignupStyling.signUpFields} fw-bold`,
                            }}
                            className={`${SignupStyling.signUpFieldsContainer} w-100 mb-2`}
                            InputProps={{
                              ...params.InputProps,
                              sx: {
                                paddingLeft: '8px', // Align text with other fields
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      backgroundColor: '#adb5bd',
                                      height: '56px', // Matches TextField height
                                      width: '40px', // Square button look
                                      borderTopRightRadius: '4px',
                                      borderBottomRightRadius: '4px',
                                    }}
                                  >
                                    <KeyboardArrowDownIcon
                                      sx={{
                                        cursor: 'pointer',
                                        color: 'white',
                                        fontSize: '24px', // Ensures proper visibility
                                      }}
                                      onClick={() =>
                                        setOpenOptPop((prev) => !prev)
                                      }
                                    />
                                  </Box>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                        open={inputValue.length > 2 && openOptPop}
                      />
                    </div>
                  )}
                </div>

                <FormHelperText className={classNames('mb-3 ms-3 text-danger')}>
                  {(formik?.touched.license_client ||
                    formik?.errors?.license_client?.max_licenses) &&
                    formik?.errors?.license_client?.max_licenses}
                </FormHelperText>

                {organisation_id ? (
                  // If organisation_id exists, show this field
                  <TextField
                    type="text"
                    placeholder="Enter Code"
                    variant="outlined"
                    name="neighbourhood_id"
                    id="neighbourhood_id"
                    value={formik?.values?.neighbourhood_id || organisation_id} // Fallback to organisation_id
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={
                      (formik?.touched.sumbited ||
                        formik?.touched.neighbourhood_id) &&
                      formik?.errors?.neighbourhood_id
                        ? true
                        : false
                    }
                    helperText={
                      (formik?.touched.sumbited ||
                        formik?.touched.neighbourhood_id) &&
                      formik?.errors?.neighbourhood_id
                    }
                    inputProps={{
                      className: `${SignupStyling.signUpFields} fw-bold`,
                    }}
                    className={`${SignupStyling.signUpFieldsContainer} w-100 mb-2`}
                  />
                ) : (
                  // If organisation_id doesn't exist, show this field
                  <TextField
                    type="text"
                    placeholder="Enter Code"
                    variant="outlined"
                    name="neighbourhood_id"
                    id="neighbourhood_id"
                    value={formik?.values?.neighbourhood_id}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={
                      (formik?.touched.sumbited ||
                        formik?.touched.neighbourhood_id) &&
                      formik?.errors?.neighbourhood_id
                        ? true
                        : false
                    }
                    helperText={
                      (formik?.touched.sumbited ||
                        formik?.touched.neighbourhood_id) &&
                      formik?.errors?.neighbourhood_id
                    }
                    inputProps={{
                      className: `${SignupStyling.signUpFields} fw-bold`,
                    }}
                    className={`${SignupStyling.signUpFieldsContainer} w-100 mb-2`}
                  />
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(
                    'w-100 fw-bold py-4 mt-2 mb-2',
                    SignupStyling.signUpBtns,
                    SignupStyling.buttonBackground,
                    SignupStyling.backgroundColorBtn
                  )}
                  onClick={formik.handleSubmit}
                >
                  {IsLoading ? <CircularProgress color="inherit" /> : 'SUBMIT'}
                </Button>
              </>
            )}
          </>
        </div>
      </RightPanelLayout>
    </>
  );
};
export default RightPanelPage2;
