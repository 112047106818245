export const videoTutorialsLink =
  'https://www.youtube.com/playlist?list=PLqYe_Fb9Fe4LlcgiDVNUg6El_4mZBQsuO';
export const chromeWebStoreLink =
  'https://chrome.google.com/webstore/detail/ahura-ai-study-sessions/plgmbcioekpgkfpgnmihadbjdkfbgmho';
export const validImageFormats = ['image/png', 'image/gif', 'image/jpeg'];

export const signUpStepYtVideoembedId = "z2PTxDIFoys"
export const genderList = [
  { id: '1', name: 'Male' },
  { id: '2', name: 'Female' },
  { id: '3', name: 'Fluid' },
];

export const CATEGORY_ID = {
  SPORTS: "7ffa3790-09a2-11ed-8c54-2bfa7b0589e3",
  FANCAM: "6f8b1d1e-c539-4580-835b-8b6b58bfan4cam",
  NEWS: "6f8g2d1n-c527-4570-100b-8bae5swfnent",
  CONTACT_US: "6fgc1e1n-c537-4580-100c-8o6nt8afc4ts",
}

export const ethnicityDropdown = [
  { id: '1', name: 'Black' },
  { id: '2', name: 'White' },
  { id: '3', name: 'Asian' },
  { id: '4', name: 'Hispanic' },
];

const getExtensionID = (defaultValue) => {
  const storedValue = localStorage.getItem("extension_id");

  // Check if the item exists in local storage
  if (storedValue !== null) {
    // Item exists, parse and return its value
    return storedValue;
  } else {
    // Item doesn't exist, return the default value
    return defaultValue;
  }
};

/** FOR PRODUCTION */
export const CHROME_EXTENSION_ID_PROD = 'plgmbcioekpgkfpgnmihadbjdkfbgmho'; // Chrome web store Ahura's extension ID

/** FOR DEVELOPMENT */
export const CHROME_EXTENSION_ID = getExtensionID(process.env.REACT_APP_CHROME_EXTENSION_ID); // Chrome web store Ahura's extension ID
// export const CHROME_EXTENSION_ID= 'booagbmomfcgagalnmagjcpajnfopiej'; // Chrome web store Ahura's extension ID
// export const CHROME_EXTENSION_ID = 'hmohpjppfdglbgbaedkhobalihnmflem'; // Dev extension ID
// export const CHROME_EXTENSION_ID = 'eimjonoccfcbgnaadnghefcekhbjlldj';
// REACT_APP_CHROME_EXTENSION_ID = plgmbcioekpgkfpgnmihadbjdkfbgmho
export const CLIENT_ID = 'cb19c961-fc50-4613-87d9-40488220a1d9';

export const PROMO_ACCOUNT_CLIENT_ID = 'f1462760-3788-11ed-84ff-df1d181fc124';

export const courseCategoryId = {
  audio: '25210020-bfc6-4b51-b1d4-8d857974bbcc',
  article: '4ffb098f-e81b-498f-bfc6-87f4d981ae00',
  courses: '6373c43b-8855-45ff-855d-b6ea05ec8a8f',
  podcast: '73d2014d-c0e0-46bc-8d7a-52ef54140c02',
  video: 'af1a2a5e-1876-4271-8be1-03121ffd37e6',
};

export const MODULE_ID = {
  CAMPAIGN: 'b6427f96-9cae-4ece-bb73-2dde4e57a734',
  NETWORK: 'ac836894-a352-4460-a011-09828c38ec10',
};

export const GROUP_APPROVAL_ID = "63a9412a-7984-4f12-be4f-ba72a829b6d9"

export const CAMPAIGN_TYPES_IDS = {
  story: '6f8b1d1e-c537-4580-835b-8b6b58bfd4ec',
  notebook: '6f8b1d1n-c537-4580-100b-8b6b58bfd4nt',
  initiative: '30a6fd93-4642-46cb-afe8-4cc830521fb9',
  post: 'cdbd0beb-41e5-4894-b932-210665665a29',
  event: '93ab8d70-3d6b-4f3f-a702-8ead10a90bcf',
  courses: '39ab8d70-3d6b-4f3f-a702-8ead10a90bcf',
  lesson: '734d8758-24de-4185-90f8-99adb47b75f3',
  section: 'e70c4cde-76f9-45b4-9352-1e2fd6ccf799',
};

export const ProfileTabIds = {
  learningPreferences: 'c981b094-85b0-11ec-a8a3-0242ac120002',
  targets: 'c981b580-85b0-11ec-a8a3-0242ac120002',
  // calibrations: 'c981b6d4-85b0-11ec-a8a3-0242ac120002',
  // badges: 'c981b7ec-85b0-11ec-a8a3-0242ac120002',
};

export const ROLE_IDS = {
  USER: '604698b9-997f-4d4b-a7e7-524cff62a4b9',
  CLIENT_OWNER: '9bed9123-06a7-4b1f-bf1f-4ed9e779fd79',
  CLIENT_ADMIN: '8aa1f764-8d6f-47c0-a049-d1239267dc74',
  PARENT_OWNER: 'de16a4f7-dcc9-45ab-bbe1-1c9ba2cc38da',
  PARENT_ADMIN: '082df2b2-b048-4b99-a019-9870b339753b',
  SUPER_OWNER: 'fd6dd9c1-96dd-4489-bfe3-ed823d5e5446',
};

export const ACCOUNT_TYPE = {
  STUDENT: 'a2ee3130-7681-11eb-9010-31966e29b0b4',
  PARENT: '9211f9d0-768d-11eb-9acb-c556ac41a913',
  TEACHER: '9cef8020-768d-11eb-9acb-c556ac41a913',
  MENTOR: 'a674c970-768d-11eb-9acb-c556ac41a913',
  COACH: 'b5b6bb50-768d-11eb-9acb-c556ac41a913',
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const daysInWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const shareButtonHostURL = process.env.REACT_APP_SHARE_BUTTON_HOST ? process.env.REACT_APP_SHARE_BUTTON_HOST : 'https://ahura.ai'