import { API_URLS } from '../../config/API_URLS/api_urls';
// import { EMAIL_REGEX_PATTERN } from '../../config/Regex/regex';
import { postApiCall } from '../../utils/axios/axios';
import * as yup from 'yup';
import ReactGA from 'react-ga4';
import { googleAnalyticsTriggerFn } from '../../utils/utility';
import { extCreateUser } from '../ExtensionHandler';

export let initialValues = {
  first_name: '',
  last_name: '',
  accept_terms: false,
  email: '',
  pass: '',
  confirm_pass: '',
  license_client: 'none',
  education_level: 'none',
  neighbourhood_id: '',
};

export const SetinitialValues = (newValues) => {
  initialValues = newValues;
};

export const UserDataValidationSchema = yup.object().shape({
  first_name: yup.string().required('Please enter you First Name'),
  last_name: yup.string().required('Please enter you Last Name'),
  accept_terms: yup
    .bool()
    .oneOf([true], 'Accept Terms & Conditions is required'),
  email: yup
    .string()
    .email('Invalid email address format')
    .required('Email is required'),
});

export const passwordValidations = {
  upperCase: false,
  lowerCase: false,
  special: false,
  numerical: false,
  length: false,
};

export const ValidatePassword = yup.object().shape({
  pass: yup
    .string()
    .required('Password is required')
    .test('length', 'Password must have more than 8 character', (value) => {
      passwordValidations.length = value && value.length >= 8;
      return value && value.length >= 8;
    })
    .test('alphabets', 'Password must contain LowerCase alphabets', (value) => {
      passwordValidations.lowerCase = RegExp('(.*[a-z].*)').test(value);
      return RegExp('(.*[a-z].*)').test(value);
    })
    .test('alphabets', 'Password must contain UpperCase alphabets', (value) => {
      passwordValidations.upperCase = RegExp('(.*[A-Z].*)').test(value);
      return RegExp('(.*[A-Z].*)').test(value);
    })
    .test('alphabets', 'Password must contain Numbers', (value) => {
      passwordValidations.numerical = RegExp('(.*\\d.*)').test(value);
      return RegExp('(.*\\d.*)').test(value);
    })
    .test('alphabet', 'Password must contain special character', (value) => {
      passwordValidations.special = RegExp('[!@#$%^&*(),.?":{}|<>]').test(
        value
      );
      return RegExp('[!@#$%^&*(),.?":{}|<>]').test(value);
    }),
  confirm_pass: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('pass'), null], 'Passwords must match'),
});

// export const licenseCodeValidate = yup.object().shape({
//   license_client: yup.object().shape({
//     max_licenses: yup
//       .number()
//       .moreThan(yup.ref('user_count'), 'Licenses Usage Limit exceeded'),
//   }),
//   neighbourhood_id: yup
//     .string()
//     .required('Enter your License Code or purchase')
//     .test('Match', 'Enter A valid code', function (value) {
//       const client = this.options?.parent?.license_client;

//       // Extract client_id correctly regardless of structure
//       const client_id =
//         client?.client_id || // If Select was used
//         client?.value?.client_id || // If Autocomplete was used
//         null;

//       // Logging for debugging
//       // console.log('🔍 Validation Debugging:');
//       // console.log('📌 Received neighbourhood_id:', value);
//       // console.log('📌 license_client:', client);
//       // console.log('📌 Extracted client_id for validation:', client_id);
//       // console.log('✅ Match result:', client_id === value);

//       return client_id === value;
//     }),
// });

export const licenseCodeValidate = yup.object().shape({
  license_client: yup.object().shape({
    max_licenses: yup
      .number()
      .moreThan(yup.ref('user_count'), 'Licenses Usage Limit exceeded'),
  }),
  neighbourhood_id: yup
    .string()
    .when(['license_client', 'license_client.client_id'], {
      is: (client, client_id) => client && client_id, // Ensure both exist
      then: (schema) =>
        schema
          .required('Enter your License Code or purchase')
          .test('Match', 'Enter a valid code', function (value) {
            const client = this.options?.parent?.license_client;
            const client_id =
              client?.client_id || client?.value?.client_id || null;
            return client_id === value;
          }),
      otherwise: (schema) => schema.notRequired(), // Skip validation if missing
    }),
});

export let UserData;

export const onSubmit = (customData) => async (values, actions) => {
  try {
    const reqBody = {
      client_id: 'cb19c961-fc50-4613-87d9-40488220a1d9',
      first_name: values?.first_name,
      last_name: values?.last_name,
      email: values?.email,
      password: values?.pass,
      education_level: values?.education_level,
      neighbourhood_id: values?.neighbourhood_id,
      skip_otp: true,
      is_token_require: true,
      source: 'web',
      userData: {},
    };
    customData.setIsLoading(true);
    const res = await postApiCall(API_URLS.sign_up, reqBody);
    res?.data?.UserClients[0] && customData.SaveUserData(res);

    (res?.data?.UserClients[0] || res?.error) && customData.setIsLoading(false);
    // res?.error && alert(res.error.message)
    UserData = res?.data;
    if (res?.data?.UserClients[0].token) {
      localStorage.setItem('token', res.data.UserClients[0].token);
      customData.setUserId(res?.data?.UserClients[0]?.user_id);
      extCreateUser(`${res?.data?.first_name} ${res?.data?.last_name}`);
      googleAnalyticsTriggerFn('Button', 'Sign Up', 'Sign Up Successfull');
    }
  } catch (err) {
    console.log('error in login', err);
    googleAnalyticsTriggerFn('Button', 'Sign Up', 'Sign Up Unsuccessfull');
  }
};

export const onSubmitV2 = (customData) => async (values, actions) => {
  console.log('Values', values);
  try {
    const reqBody = {
      client_id: 'cb19c961-fc50-4613-87d9-40488220a1d9',
      first_name: values?.first_name,
      last_name: values?.last_name,
      email: values?.email,
      password: values?.pass,
      education_level: values?.education_level,
      neighbourhood_id: values?.neighbourhood_id,
      skip_otp: true,
      is_token_require: true,
      source: 'web',
      userData: {},
    };
    customData.setIsLoading(true);
    const res = await postApiCall(API_URLS.sign_up_v2, reqBody);
    res?.data?.UserClients[0] && customData.SaveUserData(res);

    (res?.data?.UserClients[0] || res?.error) && customData.setIsLoading(false);
    // res?.error && alert(res.error.message)
    UserData = res?.data;
    if (res?.data?.UserClients[0].token) {
      localStorage.setItem('token', res.data.UserClients[0].token);
      customData.setUserId(res?.data?.UserClients[0]?.user_id);
      extCreateUser(`${res?.data?.first_name} ${res?.data?.last_name}`);
      googleAnalyticsTriggerFn('Button', 'Sign Up', 'Sign Up Successfull');
    }
  } catch (err) {
    console.log('error in login', err);
    googleAnalyticsTriggerFn('Button', 'Sign Up', 'Sign Up Unsuccessfull');
  }
};
